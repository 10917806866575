import { Injectable } from '@angular/core';
import { getCountries } from '@dep/common/countries';
import { IContact } from '@dep/common/interfaces';
import { ProductComponentType } from '@dep/common/shop-api/enums/product.enum';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

import { BasketCookie } from '../tmp-utilities/shop-api/interfaces/basket.interface';
import { IOrderProduct } from '../tmp-utilities/shop-api/interfaces/order.interface';
import { IProductComponentProduct } from '../tmp-utilities/shop-api/interfaces/product.interface';

import { UserService } from '@dep/frontend/services/user.service';
import { ProductModel } from '@dep/frontend/shop/models/product.model';
import { ProductsService } from '@dep/frontend/shop/services/products.service';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  public products: ProductModel[] = [];
  public orderProducts: Partial<IOrderProduct>[] = [];
  public extraData?: any;
  public hideLocations?: boolean;
  public basketMetadata: { shopOEName?: string; shopPath?: string; } = {};
  /** Tracks the current order products length and updates the basket indicator in the header. */
  public orderProductsCountSubject = new BehaviorSubject<number>(0);
  public productBillingGroup?: string;

  constructor(
    private readonly logger: NGXLogger,
    private readonly userService: UserService,
    public readonly productsService: ProductsService,
  ) {
    const basketCookie = localStorage.getItem('shop/basket');

    if (basketCookie && basketCookie.length > 0) {
      const basketCookieContent = JSON.parse(basketCookie) as BasketCookie;
      // Only load old basket if not older than 24 hours.
      if (basketCookieContent.lastModified + 24 * 60 * 60 * 1000 > new Date().getTime()) {
        this.logger.debug(`BasketService: Restored basket contents, valid next
        ${(((basketCookieContent.lastModified + 24 * 60 * 60 * 1000) - new Date().getTime()) / 1000)} seconds`);
        this.products = basketCookieContent.products.map((p) => new ProductModel(p));
        this.orderProducts = basketCookieContent.orderProducts;
        this.extraData = basketCookieContent.extraData;
        this.hideLocations = basketCookieContent.hideLocations;
        this.basketMetadata = basketCookieContent.basketMetadata;
        this.productBillingGroup = basketCookieContent.billingGroup;
      }
    }

    // Clear basket on logout.
    this.userService.sessionObservable$.subscribe({
      next: (cognitoUser) => {
        if (!cognitoUser) {
          this.clearBasket();
        }
      },
    });

    this.orderProductsCountSubject.next(this.orderProducts.length);
  }

  public clearBasket(): void {
    this.products = [];
    this.orderProducts = [];
    this.extraData = undefined;
    this.hideLocations = undefined;
    this.basketMetadata = {};
    this.productBillingGroup = undefined;
    localStorage.removeItem('shop/basket');
    this.orderProductsCountSubject.next(this.orderProducts.length);
    this.logger.debug('BasketService: Cleared basket');
  }

  public removeProduct(componentProduct: IProductComponentProduct): void {
    this.logger.debug('BasketService: Removing component product  from basket', componentProduct);

    this.orderProducts = this.getOrderProducts().filter((order) => order.productName !== componentProduct.product.name);
    // Remove components product from products. If there is no component product left, remove the product.
    this.products = this.products
      .map((product) => ({
        ...product,
        components: product.components.filter((c) => (
          c.type === ProductComponentType.PRODUCT
          && (c as IProductComponentProduct).product.name !== componentProduct.product.name
        )),
      }))
      .filter((product) => product.components.length > 0)
      .map((product) => new ProductModel({
        ...product,
        created: product.created.toISOString(),
        updated: product.updated.toISOString(),
      }));

    this.orderProductsCountSubject.next(this.orderProducts.length);
    this.persistBasket();

    this.logger.debug('BasketService: Removed component product with id', componentProduct.id);
  }

  /**
   * Add orderProduct to the orderProducts array.
   */
  public addOrderProduct(orderProduct: Partial<IOrderProduct>): void {
    this.orderProducts.push(orderProduct);
    this.orderProductsCountSubject.next(this.orderProducts.length);
    this.persistBasket();

    this.logger.debug('BasketService: Added new orderProduct to basket', orderProduct, this.orderProducts);
  }

  /**
   * Add product to the products array.
   */
  public addProduct(product: ProductModel): void {
    this.products.push(product);
    this.logger.debug('BasketService: Added new product to basket products', product);
  }

  public persistBasket(): void {
    this.logger.debug('BasketService: Persisting basket', this.orderProducts.length);

    if (this.products.length === 0 || this.orderProducts.length === 0) {
      this.logger.debug('BasketService: Removing basket cookie');
      localStorage.removeItem('shop/basket');
    } else {
      this.logger.debug('BasketService: Persisting basket now', this.orderProducts, this.extraData, this.hideLocations, this.basketMetadata);
      localStorage.setItem('shop/basket', JSON.stringify({
        products: this.products,
        orderProducts: this.orderProducts,
        extraData: this.extraData,
        hideLocations: this.hideLocations,
        basketMetadata: this.basketMetadata,
        lastModified: new Date().getTime(),
        billingGroup: this.productBillingGroup,
      }));
    }
  }

  public getProducts(): ProductModel[] | undefined {
    return this.products;
  }

  public getOrderProducts(): Partial<IOrderProduct>[] {
    return this.orderProducts;
  }

  /**
   * Get shipping contact from local storage extradata.location.address
   * @returns shippingContact as IContact or null
   */
  public getShippingContact(): IContact | null {
    let shippingContact = {
      id: Math.random(),
      name: '',
      street: '',
      zip: '',
      city: '',
      country: '',
    };

    if (this.extraData && this.extraData.location && this.extraData.location.isNew) { // New location
      shippingContact.name = this.extraData.location.name;
      shippingContact.street = this.extraData.location.street;
      shippingContact.zip = this.extraData.location.zip;
      shippingContact.city = this.extraData.location.city;
      shippingContact.country = this.extraData.location.country;
    } else if (this.extraData && this.extraData.location && this.extraData.location.address) { // address form selected gssn
      shippingContact = this.extraData.location.address as IContact;
    }

    // check for complete address
    if (
      !shippingContact.name
      || !shippingContact.street
      || !shippingContact.zip
      || !shippingContact.city
      || !shippingContact.country
    ) {
      this.logger.error('BasketService: Could not find shipping contact.');
      return null;
    }

    const countriesCodes = Object.entries(getCountries());
    let countryCode = countriesCodes.find((c) => c[0] === shippingContact.country);
    if (!countryCode) {
      // If the shipping contact came from node (e. g. CA), it contains the
      // country name instead of the country code.
      countryCode = countriesCodes.find((c) => c[1] === shippingContact.country);
    }
    if (!countryCode) {
      throw new Error('Shipping address country not found in countries codes list');
    }

    [shippingContact.country] = countryCode;
    this.logger.debug('BasketService: Found shipping address in extraData.', shippingContact);
    return shippingContact as IContact;
  }

  /**
   * Get an array of quantities from minimum to maximum quantity of the product.
   *
   * Example:
   * `QUANTITY_MINIMUM` = 1
   * `QUANTITY_MAXIMUM` = 5
   * --> `[1, 2, 3, 4, 5]`
   */
  public getMinMaxQuantity(p: IProductComponentProduct): number[] {
    const quantities: number[] = [];

    // Fallback 0 and 10.
    const start = p.product.price.modes.QUANTITY_MINIMUM ?? 0;
    const end = p.product.price.modes.QUANTITY_MAXIMUM ?? 10;

    for (let i = start; i <= end; ++i) {
      quantities.push(Math.floor(i));
    }

    return quantities;
  }

  /**
   * On amount change calculates and sets totalPriceNet and quantity in orderProducts
   * If the current product has dependent product (i.e. listed in PER_PRODUCT in the modes of a dependent product)
   * then also set the quantity and price for the dependent product.
   *
   * @param index - Index of the current product
   * @param amount - Amount of the current product
   * @param productComponentProducts - All sub-product components of a product
   * @param orderProducts - List of order products
   */
  public onProductAmountChange(
    index: number,
    amount: number,
    productComponentProducts: IProductComponentProduct[],
    orderProducts: Partial<IOrderProduct>[],
  ): void {
    this.updatePriceOnOrderProduct(orderProducts[index], productComponentProducts[index], amount);

    this.logger.debug('BasketService: Updating dependent products quantity');
    for (const p of productComponentProducts) {
      const foundIndex = orderProducts.findIndex((item) => item.productName === (p.product.name));
      // CASE: PER_PRODUCT
      if (p.product.price.modes && p.product.price.modes.PER_PRODUCT) {
        // Find the dependent product.
        if (p.product.price.modes.PER_PRODUCT === productComponentProducts[index].product.name) {
          this.logger.debug('BasketService: Found dependent product', p);
          // Set quantity and total price in orderProducts.
          if (foundIndex > -1) {
            orderProducts[foundIndex].quantity = amount;
            if (p.product.price.modes.PER_MONTH_UNTIL) {
              this.updatePriceOnOrderProduct(orderProducts[foundIndex], productComponentProducts[foundIndex], amount);
            }
          }
        }
      }
      // CASE: GRADUATED_PRICE
      if (p.product.price.modes.GRADUATED_PRICE) {
        let quantity = 0;
        if (p.product.price.modes.GRADUATED_PRICE.products.includes('*SHIPPING')) { // Handle special *SHIPPING case that is based on the whole basket.
          // Retrieve the identifier of the shipping product associated with the given component ID.
          const shippingProductIdentifier = this.products.find((product) => product.components.some((component) => component.id === p.id))?.identifier;
          if (!shippingProductIdentifier) {
            throw new Error('Shipping product not found');
          }

          quantity = this.productsService.calculateShippingProductQuantity(shippingProductIdentifier, this.products, orderProducts);
        } else {
          quantity = this.productsService.calculateGraduatedPriceProductQuantity(p, orderProducts);
        }

        // Calculate total prices of the product.
        const graduatedPrice = this.productsService.determineGraduatedPrice(quantity, p);
        const productTaxRate = p.product.price.taxRate ?? 0;

        orderProducts[foundIndex].totalPriceNet = graduatedPrice;
        orderProducts[foundIndex].totalPriceGross = Math.round(graduatedPrice * (1 + productTaxRate));
        orderProducts[foundIndex].quantity = quantity ? 1 : 0;
      }
    }
  }

  /**
   * Update `totalPriceNet` and `totalPriceGross` on `orderProduct`.
   *
   * @param orderProduct - Order product that will be updated
   * @param productComponentProduct - Product component used to determine price, price mode, etc.
   * @param amount - Quantity of the product component in the order/basket
   */
  private updatePriceOnOrderProduct(
    orderProduct: Partial<IOrderProduct>,
    productComponentProduct: IProductComponentProduct,
    amount: number,
  ): void {
    // Set net price.
    orderProduct.totalPriceNet = Math.floor(amount * productComponentProduct.product.price.net);
    if (productComponentProduct.product.price.modes.PER_MONTH_UNTIL) {
      // Set net price for monthly paid product (PER_MONTH_UNTIL).
      orderProduct.totalPriceNet = this.calculatePeriodInMonthsPrice(productComponentProduct) * amount;
    }

    // Set gross price.
    const productTaxRate = productComponentProduct.product.price.taxRate ?? 0;
    const productTotalPriceNet = orderProduct.totalPriceNet;
    orderProduct.totalPriceGross = Math.round(productTotalPriceNet * (1 + productTaxRate));
    this.logger.debug(
      'BasketService: Updating total gross price:',
      `${Number(orderProduct.totalPriceNet)} * ${(1 + productTaxRate)} = ${Number(orderProduct.totalPriceGross)}`,
    );
  }

  public calculatePeriodInMonthsPrice(componentProduct: IProductComponentProduct): number {
    if (!componentProduct.product.price.modes.PER_MONTH_UNTIL) {
      return componentProduct.product.price.net;
    }
    const activationDate = this.productsService.getActivationDate();
    const months = this.productsService.getLicensePeriodInMonths(componentProduct.product.price.modes.PER_MONTH_UNTIL, activationDate);
    return componentProduct.product.price.net * months;
  }

  /**
   * Get the total amount of products (excl. shipping) in basket.
   */
  public getAbsoluteProductCount(): number {
    return this.orderProducts.reduce((total, product) => Number(total) + Number(product.quantity), 0);
  }
}
