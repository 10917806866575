import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { IAPIRecordProduct } from '../tmp-utilities/shop-api/interfaces/product.interface';

import { UserService } from '@dep/frontend/services/user.service';
import { ShopCacheService } from '@dep/frontend/shop/services/cache.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private cacheService: ShopCacheService,
    private userService: UserService,
  ) { }

  /**
   * Perform an HTTP GET request.
   * If the request can use an internal cache, please use `getWithCache` instead.
   *
   * @param path HTTP path, starting with "/"
   * @param params Parameters of the GET request
   */
  public async get<T>(path: string, params?: Record<string, any>): Promise<T> {
    this.logger.debug('ApiService: Getting item(s), path: ', path);
    this.logger.debug('ApiService: Getting item(s), params: ', params);

    if (!path.startsWith('/')) {
      throw new Error('Cannot call API because of invalid path');
    }

    let urlParams: HttpParams | undefined;

    // TODO: params handling is not as expected, since it is limited to some default params while the parameter interface is Record<string, any>.
    if (params) {
      this.logger.debug('ApiService: Setting param');
      urlParams = new HttpParams();

      if (typeof params.deepResolveDepth !== 'undefined') {
        urlParams = urlParams.set('deepResolveDepth', Number(params.deepResolveDepth));
      }
      if (typeof params.filter !== 'undefined') {
        urlParams = urlParams.set('filter', JSON.stringify(params.filter));
      }
      if (typeof params.limiter !== 'undefined') {
        urlParams = urlParams.set('limiter', JSON.stringify(params.limiter));
      }
      if (typeof params.sorting !== 'undefined') {
        urlParams = urlParams.set('sorting', JSON.stringify(params.sorting));
      }
    }

    this.logger.debug('ApiService: GET with HttpParams', urlParams);
    const response = await lastValueFrom(this.http.get<T>(
      environment.config.shop.apiGateway.url + path,
      {
        params: urlParams,
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('ApiService: Got item(s)', response);

    return response;
  }

  /**
   * Perform an HTTP GET request while using an internal cache (`ShopCacheService`).
   *
   * @param path HTTP path, starting with "/"
   * @param params Parameters of the GET request
   */
  public async getWithCache<T>(path: string, params?: Record<string, any>): Promise<Observable<T>> {
    this.logger.debug('ApiService: Getting item(s) with cache', path, params);

    if (!path.startsWith('/')) {
      throw new Error('Cannot call API because of invalid path');
    }

    let urlParams: HttpParams | undefined;

    // TODO: params handling is not as expected, since it is limited to some default params while the parameter interface is Record<string, any>.
    if (params) {
      urlParams = new HttpParams();

      if (typeof params.deepResolveDepth !== 'undefined') {
        urlParams.set('deepResolveDepth', Number(params.deepResolveDepth));
      }
      if (typeof params.filter !== 'undefined') {
        urlParams = urlParams.set('filter', JSON.stringify(params.filter));
      }
      if (typeof params.limiter !== 'undefined') {
        urlParams = urlParams.set('limiter', JSON.stringify(params.limiter));
      }
      if (typeof params.sorting !== 'undefined') {
        urlParams = urlParams.set('sorting', JSON.stringify(params.sorting));
      }
    }

    let result$ = this.cacheService.getValue<T>({ path, urlParams });

    if (!result$) {
      result$ = this.http.get<T>(
        environment.config.shop.apiGateway.url + path,
        {
          params: urlParams,
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ).pipe(
        shareReplay(1),
      );
      this.cacheService.setValue({ path, urlParams }, result$);
    }

    return result$;
  }

  public async put<T>(path: string, productDBInput: IAPIRecordProduct): Promise<T> {
    this.logger.debug('ApiService: Updating item(s)', path);

    if (!path.startsWith('/')) {
      throw new Error('Cannot call API because of invalid path');
    }

    const response = await lastValueFrom(this.http.put<T>(
      environment.config.shop.apiGateway.url + path,
      productDBInput,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('ApiService: Updated item(s)', response);

    return response;
  }

  public async post<T>(path: string, productDBInput: any): Promise<T> {
    this.logger.debug('ApiService: creating item(s)', path);

    if (!path.startsWith('/')) {
      throw new Error('ApiService: Cannot call API because of invalid path');
    }

    const response = await lastValueFrom(this.http.post<T>(
      environment.config.shop.apiGateway.url + path,
      productDBInput,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('ApiService: created item(s)', response);

    return response;
  }

  public async delete<T>(path: string): Promise<T> {
    this.logger.debug('Deleting item(s)', path);

    if (!path.startsWith('/')) {
      throw new Error('ApiService: Cannot call API because of invalid path');
    }

    const response = await lastValueFrom(this.http.delete<T>(
      environment.config.shop.apiGateway.url + path,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('Deleted item(s)', response);

    return response;
  }

  /**
   * Repeats a function in a time interval where this time is always doubled until a cap is reached,
   * after timeout the function aborts automatically.
   *
   * @param tryFunction - Function to repeat it must return true when want to abort
   * @param timeoutFunction - Function will be executed after time out
   * @param timeout - Time out in milliseconds
   * @param cap - Cap time in milliseconds
   * @param interval - Interval in which the function will be called
   * @param time - Total time the function ran (sum of all `interval`s)
   * @returns Will be returned window interval, when to clear the window interval
   */
  public tryFunctionWithInterval(
    tryFunction: () => Promise<boolean>,
    timeoutFunction?: () => void,
    timeout = 5 * 60 * 1000,
    cap = 30000,
    interval = 1000,
    time = 0,
  ): void {
    window.setTimeout(async () => {
      // eslint-disable-next-line no-param-reassign
      time += interval;

      const isFinished = await tryFunction();
      if (isFinished) {
        return;
      }

      if (time >= timeout) {
        if (timeoutFunction) {
          timeoutFunction();
        }
        return;
      }

      if (interval <= cap) {
        // eslint-disable-next-line no-param-reassign
        interval *= 2;
      }
      this.tryFunctionWithInterval(tryFunction, timeoutFunction, timeout, cap, interval, time);
    }, interval);
  }
}
