import { Injectable } from '@angular/core';
import { IOrgaentityClient, IOrgaentityTreeItem, ListResponse } from '@dep/common/interfaces';
import { ProductComponentType } from '@dep/common/shop-api/enums/product.enum';
import { type IProductNotification } from '@dep/common/shop-api/interfaces/product.interface';
import { productCreateInput } from '@dep/common/shop-api/types/product.type';
import { NGXLogger } from 'ngx-logger';
import { Subject, lastValueFrom } from 'rxjs';

import { ShopUserService } from './shop-user.service';
import { ShopsService } from './shops.service';
import { IOrderProduct } from '../tmp-utilities/shop-api/interfaces/order.interface';

import { TranslationService } from '@dep/frontend/services/translation.service';
import { ProductModel } from '@dep/frontend/shop/models/product.model';
import { ApiService } from '@dep/frontend/shop/services/api.service';
import {
  IAPIRecordProduct,
  IProductAbstract,
  IProductComponent,
  IProductComponentIntroduction,
  IProductComponentProduct,
  IProductIntegration,
  IProductSettings,
} from '@dep/frontend/shop/tmp-utilities/shop-api/interfaces/product.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  /** Triggers when a product is deleted to update all product-list components. */
  public productDeleted$: Subject<void>;

  constructor(
    private readonly logger: NGXLogger,
    private readonly apiService: ApiService,
    private readonly shopsService: ShopsService,
    private readonly shopUserService: ShopUserService,
    private readonly translationService: TranslationService,
  ) {
    this.productDeleted$ = new Subject<void>();
  }

  public async createProduct(
    productComponents: IProductComponent[],
    productAbstract: IProductAbstract,
    identifier: string,
    integrations: IProductIntegration[],
    settings?: IProductSettings,
    notifications?: IProductNotification[],
    billingGroup?: string,
    derivedFromId?: number,
    derivedFromVersion?: number,
  ): Promise<ProductModel> {
    const shopId = Number((await this.shopsService.getShop())?.id || 0);
    const userId = await this.shopUserService.getUserId() || 0;

    const productInput = {
      shopId,
      identifier,
      billingGroup,
      settings: settings || {},
      notifications: notifications || [],
      abstract: productAbstract,
      components: productComponents,
      integrations,
      derivedFromId,
      derivedFromVersion,
      userId,
    };

    this.logger.debug('ProductsService: Create productInput', productInput);

    const parsedProductInput = productCreateInput.parse(productInput);
    const result = await this.apiService.post<IAPIRecordProduct>(`/shops/${shopId}/products`, parsedProductInput);

    this.logger.debug('ProductsService: Create result from API', result);
    return new ProductModel(result);
  }

  public async updateProduct(
    productComponents: IProductComponent[],
    productAbstract: IProductAbstract,
    updateProduct: ProductModel,
    identifier: string,
    settings: IProductSettings,
    integrations: IProductIntegration[],
    notifications: IProductNotification[],
    billingGroup?: string,
  ): Promise<ProductModel | undefined> {
    this.logger.debug('Updating product:', updateProduct);
    const productInput: IAPIRecordProduct = {
      id: updateProduct.id,
      version: updateProduct.version + 1,
      shopId: updateProduct.shopId,
      identifier,
      billingGroup,
      settings,
      notifications,
      abstract: productAbstract,
      components: productComponents,
      integrations,
      derivedFromId: updateProduct.derivedFromId,
      derivedFromVersion: updateProduct.derivedFromVersion,
      userId: updateProduct.userId,
    };
    const result = await this.apiService.put<IAPIRecordProduct>(`/shops/${updateProduct.shopId}/products/${updateProduct.id}`, productInput);
    const updatedProduct = new ProductModel(result);

    this.logger.debug('ProductsService: Update result from API', result);
    return updatedProduct;
  }

  /**
   * Delete a product by ID.
   * On successful delete, clears the shop and product cache and triggers observable to reinitialize product list component(s).
   */
  public async deleteProduct(productId: number): Promise<void> {
    this.logger.debug('Delete product with ID', productId);

    const shopId = (await this.shopsService.getShop())?.id;
    if (!shopId) {
      this.logger.error('Could not find shop ID');
      throw new Error('Shop ID not found');
    }

    let deletedProductIds: number[] = [];
    try {
      deletedProductIds = await this.apiService.delete<number[]>(`/shops/${shopId}/products/${productId}`);
    } catch (e) {
      this.logger.error('Error occurred while deleting product', e);
      throw new Error('Delete product failed');
    }

    this.logger.debug('Got deleted product IDs from API', deletedProductIds);
    // Remove cached product and shop.
    this.shopsService.clear();

    this.logger.debug('Triggering product deleted observable');
    this.productDeleted$.next();
  }

  public async listProductsOfShop(params?: Record<string, any>): Promise<ListResponse<ProductModel>> {
    this.logger.debug('ProductsService: Listing products');
    const shopId = (await this.shopsService.getShop())?.id;
    if (!shopId) {
      return {
        items: [],
        count: undefined,
      };
    }

    const apiProducts = await this.apiService.get<IAPIRecordProduct[]>(`/shops/${shopId}/products`, params);
    this.logger.debug('ProductsService: Got products from API');

    return {
      items: apiProducts.map((apiProduct) => new ProductModel(apiProduct)),
      count: undefined,
    };
  }

  /**
   *
   * @param productId
   * @param version Specific version to be fetched
   * @param shopId If the product should be fetched from another shop than the current one
   */
  public async getProductById(productId: number, version?: number, shopId?: number): Promise<ProductModel> {
    this.logger.debug('ProductsService: Listing products');
    const defaultShopId = (await this.shopsService.getShop())?.id;
    const pathShopId = shopId ?? defaultShopId;
    if (!pathShopId) {
      throw new Error('Could not get product, shop not set');
    }

    const apiProducts = await this.apiService.get<IAPIRecordProduct[]>(`/shops/${pathShopId}/products/${productId}${version ? '?version=' + version : ''}`);
    this.logger.debug('ProductsService: Got products from API', apiProducts);
    if (apiProducts.length !== 1) {
      this.logger.error('ProductsService: Could not get product, none or too many results', productId, apiProducts.length);
      throw new Error('Could not get product, none or too many results');
    }

    return new ProductModel(apiProducts[0]);
  }

  /**
   * First, get all the Orgaentity IDs that the user has shop rights for.
   * Then, fetch all the shared products for these Orgaentities from the API with given params (limiter, filters).
   *
   * @returns Products that were shared with the shop(s) of the current user
   */
  public async listSharedProductsOfShop(params?: Record<string, any>): Promise<ListResponse<ProductModel>> {
    this.logger.debug('ProductsService: Listing shared products');

    const orgaentityIds = await this.shopUserService.getOrgaentityIds();
    const getSharedProductsPromises = orgaentityIds.map(async (orgaentityId) => {
      const sharedProducts$ = await this.apiService.getWithCache<IAPIRecordProduct[]>(`/sharedproducts/${orgaentityId}`, params);
      return lastValueFrom(sharedProducts$);
    });

    const getSharedProductsResults = await Promise.all(getSharedProductsPromises);
    this.logger.debug('ProductsService: Got all shared products', getSharedProductsResults);

    // Flatten the result and filter all duplicates (products that are shared with multiple
    // shops of the user).
    const sharedProducts = getSharedProductsResults.flat().filter(
      (value, index, self) => index === self.findIndex((t) => (
        t.id === value.id
      )),
    );
    return {
      items: sharedProducts.map((apiProduct) => new ProductModel(apiProduct)),
      count: undefined,
    };
  }

  public async getSharedProductById(productId: number): Promise<ProductModel | undefined> {
    const params = {
      filter: {
        strategy: 'and',
        operator: 'where',
        list: [{
          field: 'id',
          additionalOperator: '=',
          value: productId,
        }],
      },
    };

    const result: ListResponse<ProductModel> = await this.listSharedProductsOfShop(params);

    if (result.items.length !== 1) {
      return undefined;
    }
    return result.items[0];
  }

  public getProductComponent(p: ProductModel): any {
    for (const c of p.components) {
      if (c.type === ProductComponentType.PRODUCT) {
        return (c as IProductComponentProduct).product;
      }
    }
    return null;
  }

  public getIntroductionComponent(p: ProductModel): IProductComponentIntroduction | undefined {
    for (const c of p.components) {
      if (c.type === ProductComponentType.INTRODUCTION) {
        return (c as IProductComponentIntroduction);
      }
    }
    return undefined;
  }

  public getProductComponentProducts(p: ProductModel): IProductComponentProduct[] {
    const productComponentlist: IProductComponentProduct[] = [];
    for (const c of p.components) {
      if (c.type === ProductComponentType.PRODUCT) {
        productComponentlist.push(c as IProductComponentProduct);
      }
    }
    return productComponentlist;
  }

  /**
   * Sends a Date in format 2022-01-01T00:00:00.000Z depending on a given date or
   * current date (present day, month, year) when no date is given.
   * @param referenceDate - Reference date on which the activation date will be determined (usually the order creation date)
   * @returns ISO 8601 activation date
   */
  public getActivationDate(referenceDate?: Date): string {
    const d = referenceDate ? referenceDate.getDate() : new Date().getDate();
    let m = referenceDate ? referenceDate.getMonth() + 1 : new Date().getMonth() + 1;
    let y = referenceDate ? referenceDate.getFullYear() : new Date().getFullYear();
    // From the 27th of the month, do not offer the current month anymore.
    if (d > 26) {
      m++;
      if (m > 12) {
        m = 1;
        y++;
      }
    }
    return y + '-' + ('0' + m).slice(-2) + '-01T00:00:00.000Z';
  }

  /**
   * Calculates the difference of two dates in full months (counting partial months
   * i.e. counting the month of activation date (d1)).
   * `activationDate` already takes care of partial month (always first of the month).
   * "END_OF_YEAR" = current year + 1
   * "END_OF_YEAR" is calculated based on the activationDate when provided, otherwise current year
   * @example If current year is 2022, END_OF_YEAR = 2023-01-01T01:00:00.000Z
   * @returns Full months difference from now to `endUntil` mode
   */
  public getLicensePeriodInMonths(endUntil: string, activationDate?: string): number {
    this.logger.debug('ProductsService: Getting license period in months', endUntil, activationDate);

    /**
     * Calculates the difference of two dates in full months (counting partial months
     * i.e. counting the month of activation date (d1)).
     *
     * @see https://stackoverflow.com/a/2536445
     * @param d1 Date 1 (must be before `d2`)
     * @param d2 Date 2 (must be after `d1`)
     * @returns Full months difference
     * @example `d1` 2020-03-01 00:00:00 and `d2` 2021-01-01 01:00:00 => 10 months.
     * @example `d1` 2020-04-01 00:00:00 and `d2` 2021-01-01 01:00:00 => 09 months.
     * @example `d1` 2020-05-01 00:00:00 and `d2` 2021-01-01 01:00:00 => 08 months.
     * @example `d1` 2020-06-01 00:00:00 and `d2` 2021-01-01 01:00:00 => 07 months.
     */
    const monthDiff = (d1: Date, d2: Date) => {
      let months = (d2.getUTCFullYear() - d1.getUTCFullYear()) * 12;
      months -= d1.getUTCMonth();
      months += d2.getUTCMonth();

      return months <= 0 ? 0 : months;
    };

    let periodMonths = 0;

    // Currently, only END_OF_YEAR is supported.
    if (endUntil === 'END_OF_YEAR') {
      // End of year is calculated based on the activationDate when provided, otherwise current year
      const endYear = activationDate ? new Date(activationDate).getFullYear() + 1 : new Date().getFullYear() + 1;
      periodMonths = monthDiff(
        new Date(activationDate || new Date().toISOString()),
        new Date(endYear + '-01-01T01:00:00.000Z'),
      );
    } else {
      this.logger.error('ProductsService: Unknown end period. Returning 0');
    }

    return periodMonths;
  }

  /**
   * Gets the calculation period for products which are calculated by months from the given activatioDate.
   * The period is calculated from the month and up to the end of the year (31st Dec) of the activationDate.
   * @param activationDate - The date to calculate period from
   * @returns calcPeriod as string
   * @example
   * // activationDate:
   * `2022-05-01T00:00:000Z`
   * // returns
   * `May - Dec/2022`
   */
  public getCalcPeriodString(activationDate: string): string {
    const y = new Date(activationDate).getFullYear();
    const monthFrom = this.translationService.getMonthShortFromDate(new Date(activationDate));
    const monthTo = this.translationService.getMonthShortFromDate(new Date(y + '-12-31T00:00:00.000Z'));
    const calcPeriod = monthFrom + ' - ' + monthTo + '/' + y;
    this.logger.debug(`ProductsService: Calculation period: ${calcPeriod}`);

    return calcPeriod;
  }

  public isLicenseRenewable(item: IOrgaentityTreeItem['item'], shopProduct: ProductModel): boolean {
    this.logger.debug('Checking if license is renewable', item, shopProduct);

    if (!shopProduct.settings.renewsProduct) {
      return false;
    }

    if (shopProduct.settings.renewsProduct === 'DIGITAL_SIGNAGE') { // RCPS Player license
      const dsRenewalIntegration = shopProduct.integrations.find(
        (integration) => integration.service === 'DEP_CLIENT_RENEWAL',
      );
      const digitalSignageLicenseValidity = dsRenewalIntegration?.depClientRenewalOptions?.renewUntilDate;
      if (!digitalSignageLicenseValidity) {
        this.logger.error('No DIGITAL_SIGNAGE renewUntilDate set in product', shopProduct, dsRenewalIntegration);
        return false;
      }

      // Only CLIENTs can have a DS license.
      return item.type === 'CLIENT'
        // Sales Tablets do not have a DS license.
        && (item as IOrgaentityClient).orgaentitySubtypeId !== 2
        // It is only possible to renew licenses that have not been renewed yet.
        && new Date((item as IOrgaentityClient).validUntil) < new Date(digitalSignageLicenseValidity);
    }
    if (shopProduct.settings.renewsProduct === 'ONEMIRROR') { // OneMirror license
      // Get the license validity from the shop product integrations.
      const lmsRenewalIntegration = shopProduct.integrations.find(
        (integration) => integration.service === 'LMS_RENEWAL' && integration.options?.lmsLicenseName === 'OneMirror Server Pro',
      );
      const lmsLicenseValidity = lmsRenewalIntegration?.options?.lmsLicenseValidity;
      if (!lmsLicenseValidity) {
        this.logger.error('No lmsLicenseValidity set in product', shopProduct, lmsRenewalIntegration);
        return false;
      }

      // Get the client license's validity date.
      const oneMirrorLicense = (item as IOrgaentityClient).licenses?.find((license) => license.name === 'OneMirror Server Pro');

      // Get the current license validity.
      const oneMirrorLicenseValidity = new Date(oneMirrorLicense?.expires ?? 0);
      // Add one day for tolerance reasons (e. g. 31.12. vs. 01.01.).
      oneMirrorLicenseValidity.setDate(oneMirrorLicenseValidity.getDate() + 1);
      this.logger.debug('OneMirror license validity comparison', oneMirrorLicenseValidity, lmsLicenseValidity);

      // Only CLIENTs can have a OneMirror license.
      return item.type === 'CLIENT'
        // Eligible for renewal if there is no license yet (oneMirrorLicenseValidity = 0)
        // or the license renewal will extend the license (old validity < new validity date).
        && oneMirrorLicenseValidity < new Date(lmsLicenseValidity);
    }

    this.logger.debug('License is not renewable because no fitting renewsProduct was found', item, shopProduct.settings.renewsProduct);
    return false;
  }

  /**
   * Determine the graduated price based on the given quantity and a product.
   *
   * @param quantity - The quantity for which to determine the graduated price
   * @param p - Component product
   * @returns The graduated price corresponding to the quantity
   */
  public determineGraduatedPrice(quantity: number, p: IProductComponentProduct): number {
    if (!quantity) {
      return 0;
    }

    if (p.product.price.modes.GRADUATED_PRICE) {
      for (let i = 0; i < p.product.price.modes.GRADUATED_PRICE.levels.length - 1; i++) {
        const currentQuantity = p.product.price.modes.GRADUATED_PRICE.levels[i].quantity;
        const nextQuantity = p.product.price.modes.GRADUATED_PRICE.levels[i + 1].quantity;

        if (quantity >= currentQuantity && quantity < nextQuantity) {
          return p.product.price.modes.GRADUATED_PRICE.levels[i].price * 100;
        }
      }

      // Check if quantity is greater than or equal to the last item's quantity.
      const lastQuantity = p.product.price.modes.GRADUATED_PRICE.levels[p.product.price.modes.GRADUATED_PRICE.levels.length - 1].quantity;
      if (quantity >= lastQuantity) {
        return p.product.price.modes.GRADUATED_PRICE.levels[p.product.price.modes.GRADUATED_PRICE.levels.length - 1].price * 100;
      }
    }

    return Number(p.product.price.net);
  }

  /**
   * Calculates the total quantity of order products,
   * excluding all products that are not listed in the 'GRADUATED_PRICE.products' array.
   *
   * @param graduatedPriceProduct - Product with GRADUATED_PRICE mode
   * @param orderProducts - List of order products
   * @returns The total quantity of order products
   */
  public calculateGraduatedPriceProductQuantity(
    graduatedPriceProduct: IProductComponentProduct,
    orderProducts: Partial<IOrderProduct>[],
  ): number {
    return orderProducts
      .filter(
        (product) => product.productName
          && graduatedPriceProduct.product.price.modes.GRADUATED_PRICE?.products.includes(product.productName)
          && product.quantity,
      )
      .reduce((sum, product) => sum + Number(product.quantity), 0);
  }

  /**
   * Calculates the total quantity of a shipping product by summing the quantities of all related products
   * that have a `shippingProductIdentifier` matching the given shipping product's identifier.
   *
   * @param shippingProductIdentifier - The dependent shipping product identifier
   * @param basketProducts - All products in the basket
   * @param orderProducts - List of order products
   */
  public calculateShippingProductQuantity(
    shippingProductIdentifier: string,
    basketProducts: ProductModel[],
    orderProducts: Partial<IOrderProduct>[],
  ): number {
    return basketProducts
      .filter((product) => product.settings.shippingProductIdentifier === shippingProductIdentifier)
      .flatMap((product) => this.getProductComponentProducts(product))
      .reduce((total, component) => total + (orderProducts.find((op) => op.productName === component.product.name)?.quantity ?? 0), 0);
  }
}
